import { ApplicationRef, DestroyRef, inject, Injectable, ViewContainerRef } from '@angular/core';
import { ModalService } from './modal.service';
import { CookiesPopupComponent } from '../components/cookies/cookies-popup/cookies-popup.component';
import { first, switchMap, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IndexedDBPersistence } from '@owain/indexeddb/IndexedDB-persistence.service';
import { CookiePreferencesStore } from '../store/cookie-preferences/cookie-preferences.store';
import { WINDOW } from '@owain/tokens/window.provider';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CookiePreferencesService {
  private readonly window: Window = inject(WINDOW);
  private readonly applicationRef: ApplicationRef = inject(ApplicationRef);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly modalService: ModalService<CookiesPopupComponent> = inject(ModalService<CookiesPopupComponent>);
  private readonly indexedDBPersistence: IndexedDBPersistence = inject(IndexedDBPersistence);
  private readonly cookiePreferencesStore: InstanceType<typeof CookiePreferencesStore> = inject(CookiePreferencesStore);

  public checkPreferences(viewContainerRef: ViewContainerRef): void {
    this.applicationRef.isStable
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        first((isStable: boolean): boolean => isStable),
        tap((): void => this.cookiePreferencesStore.init()),
        switchMap((): Observable<unknown> => this.indexedDBPersistence.getItem('cookie-preferences-store-state')),
        tap((item: any): void => {
          if (!item) {
            this.open(viewContainerRef);
            return;
          }

          try {
            if (!item.saved) {
              this.open(viewContainerRef);
            }
          } catch (e) {
            this.open(viewContainerRef);
          }
        })
      )
      .subscribe();
  }

  public open(viewContainerRef: ViewContainerRef): void {
    const detectRobot: (userAgent: string) => boolean = (userAgent: string): boolean => {
      const robots = new RegExp(
        (
          [
            /bot/,
            /spider/,
            /crawl/, // GENERAL TERMS
            /APIs-Google/,
            /AdsBot/,
            /Googlebot/, // GOOGLE ROBOTS
            /mediapartners/,
            /Google Favicon/,
            /FeedFetcher/,
            /Google-Read-Aloud/,
            /DuplexWeb-Google/,
            /googleweblight/,
            /bing/,
            /yandex/,
            /baidu/,
            /duckduck/,
            /yahoo/, // OTHER ENGINES
            /ecosia/,
            /ia_archiver/,
            /facebook/,
            /instagram/,
            /pinterest/,
            /reddit/, // SOCIAL MEDIA
            /slack/,
            /twitter/,
            /whatsapp/,
            /youtube/,
            /semrush/, // OTHER
          ] as RegExp[]
        )
          .map((r: RegExp) => r.source)
          .join('|'),
        'i'
      ); // BUILD REGEXP + "i" FLAG

      return robots.test(userAgent);
    };

    if (!detectRobot(this.window.navigator.userAgent)) {
      const inEu: boolean = Intl.DateTimeFormat().resolvedOptions().timeZone.startsWith('Europe');

      if (inEu) {
        this.modalService.viewContainerRef = viewContainerRef;
        this.modalService.open(CookiesPopupComponent);
      } else {
        this.cookiePreferencesStore.acceptAll();
      }
    } else {
      this.cookiePreferencesStore.acceptAll();
    }
  }

  public close(): void {
    this.modalService.close();
  }
}
