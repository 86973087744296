<app-modal #modalComponent>
  <div slot="popup">
    <form (ngSubmit)="saveSelection($event.submitter.id)" [formGroup]="cookieForm" class="space-y-6" errorTailor>
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 dark:bg-slate-800">
        @defer (hydrate never) {
          <fast-svg class="text-gray-900 dark:text-gray-100" name="icons/cog" size="25" />
        }
      </div>
      <div class="mt-3 sm:mt-5 prose text-center">
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100" id="modal-title">
          {{ 'cookiepreferences' | transloco }}
        </h3>
      </div>
      <div class="prose">
        @if (!cookieDetails()) {
          <div class="mt-2">
            @if (language() === 'nl') {
              <p class="text-sm text-gray-600 dark:text-gray-200 text-justify">
                Een cookie is een klein bestand met gegevens dat uw browser opslaat op uw apparaat. De gegevens in dit
                bestand worden doorgaans gedeeld met de eigenaar van de website, evenals met potentiële partners en derden
                van dat bedrijf. Het verzamelen van deze gegevens kan worden gebruikt voor het functioneren van de website
                en/of om uw ervaring te verbeteren
              </p>
            } @else if (language() === 'en' || language() === null) {
              <p class="text-sm text-gray-600 dark:text-gray-200 text-justify">
                A cookie is a small file with information that your browser stores on your device. Information in this
                file is typically shared with the owner of the site in addition to potential partners and third parties to
                that business. The collection of this information may be used in the function of the site and/or to
                improve your experience.
              </p>
            }
          </div>
        }
        <div class="mt-2">
          <p class="text-sm text-gray-600 dark:text-gray-200 text-justify">
            {{ 'cookiepreferenceschange' | transloco }}
          </p>
        </div>
        @if (cookieDetails()) {
          <div class="mt-2">
            <dl
              class="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500 text-sm leading-6 duration-1000">
              <div class="pt-6">
                <div class="flex">
                  <dt class="flex-none pr-6 font-medium text-gray-900 dark:text-gray-100 sm:w-64 duration-1000 not-prose">
                    {{ 'cookiefunctional' | transloco }} ({{ 'cookierequired' | transloco }})
                  </dt>
                  <dd class="flex flex-auto items-center justify-end not-prose">
                    <label class="relative inline-flex items-center cursor-pointer">
                      <input checked class="sr-only peer" disabled type="checkbox" value="" />
                      <div
                        class="w-11 h-6 bg-green-900 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-gray-400 dark:peer-checked:after:border-gray-900 after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-gray-400 dark:after:bg-gray-900 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-500"></div>
                    </label>
                  </dd>
                </div>
                <ul class="text-gray-600 dark:text-gray-400 text-sm">
                  <li>{{ 'cookiefunctionalloggedin' | transloco }}</li>
                  <li>{{ 'cookiefunctionalstupidpopup' | transloco }}</li>
                </ul>
              </div>
              <div class="pt-6">
                <div class="flex">
                  <dt class="flex-none pr-6 font-medium text-gray-900 dark:text-gray-100 sm:w-64 duration-1000 not-prose">
                    Google reCAPTCHA ({{ 'cookierequired' | transloco }})
                  </dt>
                  <dd class="flex flex-auto items-center justify-end not-prose">
                    <label class="relative inline-flex items-center cursor-pointer">
                      <input checked class="sr-only peer" disabled type="checkbox" value="" />
                      <div
                        class="w-11 h-6 bg-green-900 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-gray-400 dark:peer-checked:after:border-gray-900 after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-gray-400 dark:after:bg-gray-900 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-500"></div>
                    </label>
                  </dd>
                </div>
                <ul class="text-gray-600 dark:text-gray-400 text-sm">
                  <li>{{ 'cookierecaptcha' | transloco }}</li>
                </ul>
              </div>
              <div class="pt-6">
                <div class="flex">
                  <dt class="flex-none pr-6 font-medium text-gray-900 dark:text-gray-100 sm:w-64 duration-1000 not-prose">
                    Google Maps
                  </dt>
                  <dd class="flex flex-auto items-center justify-end not-prose">
                    <label class="relative inline-flex items-center cursor-pointer">
                      <input class="sr-only peer" formControlName="googleMaps" type="checkbox" />
                      <div
                        class="w-11 h-6 bg-red-600 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-500 peer-checked:bg-green-600"></div>
                    </label>
                  </dd>
                </div>
                <ul class="text-gray-600 dark:text-gray-400 text-sm">
                  <li>{{ 'cookiemaps' | transloco }}</li>
                </ul>
              </div>
              <div class="pt-6">
                <div class="flex">
                  <dt class="flex-none pr-6 font-medium text-gray-900 dark:text-gray-100 sm:w-64 duration-1000 not-prose">
                    Cloudflare Insights
                  </dt>
                  <dd class="flex flex-auto items-center justify-end not-prose">
                    <label class="relative inline-flex items-center cursor-pointer">
                      <input class="sr-only peer" formControlName="cloudflareInsights" type="checkbox" />
                      <div
                        class="w-11 h-6 bg-red-600 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-500 peer-checked:bg-green-600"></div>
                    </label>
                  </dd>
                </div>
                <ul class="text-gray-600 dark:text-gray-400 text-sm">
                  <li>{{ 'cookieinsights' | transloco }}</li>
                </ul>
              </div>
              <div class="pt-6">
                <div class="flex">
                  <dt class="flex-none pr-6 font-medium text-gray-900 dark:text-gray-100 sm:w-64 duration-1000 not-prose">
                    Google Tag Manager
                  </dt>
                  <dd class="flex flex-auto items-center justify-end not-prose">
                    <label class="relative inline-flex items-center cursor-pointer">
                      <input class="sr-only peer" formControlName="gtag" type="checkbox" />
                      <div
                        class="w-11 h-6 bg-red-600 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-500 peer-checked:bg-green-600"></div>
                    </label>
                  </dd>
                </div>
                <ul class="text-gray-600 dark:text-gray-400 text-sm">
                  <li>{{ 'cookieinsights' | transloco }}</li>
                </ul>
              </div>
            </dl>
          </div>
        }
      </div>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        @if (!cookieDetails()) {
          <button
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-slate-800 text-base font-medium text-white hover:bg-slate-700 focus:outline-none sm:text-sm cursor-pointer"
            type="button"
            (click)="toggleDetails()">
            {{ 'configure' | transloco }}
          </button>
        } @else {
          <button
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-slate-800 text-base font-medium text-white hover:bg-slate-700 focus:outline-none sm:text-sm"
            id="selection"
            type="submit">
            {{ 'cookieselection' | transloco }}
          </button>
        }
        <button
          class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none sm:mt-0 sm:text-sm"
          id="all"
          type="submit">
          {{ 'cookieacceptall' | transloco }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
