import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  Signal,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { CookiePreferencesStore } from '../../../store/cookie-preferences/cookie-preferences.store';
import { FastSvgComponent } from '@owain/fast-svg/fast-svg.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormActionDirective } from '@ngneat/error-tailor';
import { SvgRegistry } from '@owain/fast-svg/svg-registry.service';
import { JsonLdService } from '../../../services/seo/json-ld.service';
import { ToasterService } from '@owain/notifier/lib/services/toaster.service';
import { NotificationType } from '@owain/notifier/lib/models/notifications.model';
import { ApplicationUiStore } from '../../../store/application-ui/application-ui.store';

@Component({
  selector: 'app-cookies-popup-modal',
  templateUrl: './cookies-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ModalComponent, ReactiveFormsModule, FastSvgComponent, TranslocoPipe, FormActionDirective],
})
export class CookiesPopupComponent implements OnInit {
  public readonly cookieDetails: WritableSignal<boolean> = signal(false);
  private readonly svgRegistry: SvgRegistry = inject(SvgRegistry);
  private readonly modal: Signal<ModalComponent<any>> = viewChild.required('modalComponent', { read: ModalComponent });
  private readonly cookiePreferencesStore: InstanceType<typeof CookiePreferencesStore> = inject(CookiePreferencesStore);
  private readonly applicationUiStore: InstanceType<typeof ApplicationUiStore> = inject(ApplicationUiStore);
  public readonly language: Signal<string | null> = this.applicationUiStore.language;
  private readonly toastService: ToasterService = inject(ToasterService);
  private readonly translocoService: TranslocoService = inject(TranslocoService);
  private readonly formBuilder: FormBuilder = inject(FormBuilder);
  public cookieForm: FormGroup = this.formBuilder.nonNullable.group({
    googleMaps: [this.cookiePreferencesStore.googleMaps(), [Validators.required]],
    cloudflareInsights: [this.cookiePreferencesStore.cloudflareInsights(), [Validators.required]],
    gtag: [this.cookiePreferencesStore.gtag(), [Validators.required]],
  });
  private readonly jsonLdService: JsonLdService = inject(JsonLdService);

  ngOnInit(): void {
    this.svgRegistry.fetchSvgs('icons/cog');

    this.jsonLdService.addHeroiconImage('icons/cog');

    this.jsonLdService.inject();

    if (!this.cookiePreferencesStore.saved()) {
      this.cookieForm.patchValue({
        googleMaps: true,
        cloudflareInsights: true,
        gtag: true,
      });
    }
  }

  public toggleDetails(): void {
    this.cookieDetails.set(!this.cookieDetails());
  }

  public saveSelection(event: string): void {
    if (event === 'selection') {
      if (!this.cookiePreferencesStore.saved()) {
        this.cookiePreferencesStore.setSaved(true);
      }

      if (this.cookiePreferencesStore.googleMaps() !== this.cookieForm.value.googleMaps) {
        this.cookiePreferencesStore.setGoogleMaps(this.cookieForm.value.googleMaps);
      }

      if (this.cookiePreferencesStore.cloudflareInsights() !== this.cookieForm.value.cloudflareInsights) {
        this.cookiePreferencesStore.setCloudflareInsights(this.cookieForm.value.cloudflareInsights);
      }

      if (this.cookiePreferencesStore.gtag() !== this.cookieForm.value.gtag) {
        this.cookiePreferencesStore.setGtag(this.cookieForm.value.gtag);
      }
    } else {
      if (!this.cookiePreferencesStore.saved()) {
        this.cookiePreferencesStore.setSaved(true);
      }

      if (!this.cookiePreferencesStore.googleMaps()) {
        this.cookiePreferencesStore.setGoogleMaps(true);
      }

      if (!this.cookiePreferencesStore.cloudflareInsights()) {
        this.cookiePreferencesStore.setCloudflareInsights(true);
      }

      if (!this.cookiePreferencesStore.gtag()) {
        this.cookiePreferencesStore.setGtag(true);
      }
    }

    this.toastService.notification({
      alertType: NotificationType.SUCCESS,
      message: this.translocoService.translate('cookiessaved'),
    });

    this.close();
  }

  public close(): void {
    if (this.modal()) {
      this.modal().close();
    }
  }
}
